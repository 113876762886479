import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6oy0ulSF66 from "/vercel/path0/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_ksq7gplbDw from "/vercel/path0/node_modules/@twicpics/components/nuxt3/plugin.js";
import strapi_CICqBBANNd from "/vercel/path0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_gtag_client_CYt6toK02h from "/vercel/path0/plugins/vue-gtag.client.ts";
import vue_gtm_client_stBjLl0OeM from "/vercel/path0/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_6oy0ulSF66,
  plugin_BqLkNUef68,
  plugin_ksq7gplbDw,
  strapi_CICqBBANNd,
  chunk_reload_client_UciE0i6zes,
  vue_gtag_client_CYt6toK02h,
  vue_gtm_client_stBjLl0OeM
]