<template>
  <div class="wrapper">
    <ShadowedText class="favorite-title" :text="spanTitle" />
    <div class="bottom-container">
      <div class="information-container">
        <ShadowedText class="info-title" :text="spanTitle" />
        <div class="top-info">
          <div v-if="journey.attributes.generalTravelType" class="flex flex-row">
            <Icon
              v-if="journey.attributes.generalTravelType"
              :name="rewriteIcon(journey.attributes.generalTravelType)"
              class="info-icon"
              stroke="white"
              width="16"
              height="16"
            />

            <span>{{ TRAVEL_STYLE_NAME[journey.attributes.generalTravelType] }}</span>
          </div>
          <!-- TODO: add trust pilot rating in V2 -->
          <!-- <div>
            <Icon name="star-empty" class="info-icon" width="12" height="12" fill="white" />
            <span> 4/5 </span>
          </div> -->
        </div>
        <div class="days-nights-info">
          <div v-if="journey.attributes.days && journey.attributes.days > 0">
            <Icon name="day" class="info-icon" stroke="white" width="20px" height="20px" />
            <span>
              {{ `${journey.attributes.days} ${journey.attributes.days > 1 ? 'jours' : 'jour'}` }}
            </span>
          </div>
          <div v-if="journey.attributes.nights && journey.attributes.nights > 0">
            <Icon
              name="night"
              class="info-icon night-icon"
              stroke="white"
              width="16px"
              height="16px"
            />
            <span>
              {{
                `${journey.attributes.nights} ${journey.attributes.nights > 1 ? 'nuits' : 'nuit'}`
              }}
            </span>
          </div>
        </div>
        <!-- TODO: add it on V2 -->
        <!-- <div v-if="locations?.length ?? 0 > 0" class="locations mt-2 flex flex-wrap text-white">
          <Icon name="map-point-outline" class="info-icon" stroke="white" width="16" height="16" />
          <p v-for="location in locations" :key="location">
            {{ location }}
          </p>
        </div> -->

        <div class="price-info">
          <div class="from-price-wrapper">
            <span class="from-price">à partir de</span>
            <div class="price">{{ formattedPrice }}</div>
          </div>
          <div class="discover-button">En découvrir +</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { rewriteIcon } from '@/lib/utils';

import { Journey } from '@/lib/strapi-types/Journey';

import TRAVEL_STYLE_NAME from '@/lib/types/enums/travelStyleName.enum';

const { journey, width } = defineProps<{
  journey: Journey;
  width?: string;
  height?: string;
}>();

const formattedPrice = computed(() => formatPrice(journey.attributes.price ?? 0));

const widthData = computed(() => `${width}px`);

const spanTitle = `<div class="title">${journey.attributes.title}</div>`;

// TODO: add it on V2
// const locations = computed(() => {
//   const locations = journey.attributes.watabiContent?.location
//     ?.map(location => location.name)
//     // Remove duplicates
//     .filter((locationName, index, self) => self.indexOf(locationName) === index);

//   const locationsString = locations?.slice(0, 10).join(', ');
//   const suffix = (locations?.length ?? 0) > 10 ? '...' : '';

//   return locationsString + suffix;
// });
</script>

<style lang="scss" scoped>
@use '$/components/shadowed-text.scss';
@use '$/animation.scss';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';
@use '$/text-ellipsis.scss';

.wrapper {
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .favorite-title {
    @include shadowed-text.small();

    z-index: 2;
    margin: 24px 18px;
  }

  .bottom-container {
    @include animation.fade-in(0.2s);

    position: absolute;
    bottom: 0;

    display: none;
    flex-direction: column;
    justify-content: flex-end;

    width: 100%;
    height: 100%;
    padding: 16px;

    font-size: 13px;

    background: linear-gradient(360deg, rgb(0 0 0 / 50%) 55.36%, rgb(0 0 0 / 0%) 98.55%);
    border-radius: border-radius.$small;

    .information-container {
      $small-font-size: 13px;

      @include animation.slide-in(0.2s);

      transform: translateY(100%);
      flex-direction: column;
      margin-top: 24px;

      .info-title {
        @include shadowed-text.small();

        margin-bottom: 24px;
      }

      .top-info {
        align-items: center;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;
          color: white;
        }
      }

      .days-nights-info {
        margin-top: 8px;

        span {
          color: white;
        }
      }

      .info-icon {
        margin-right: 5px;
      }

      .night-icon {
        margin-left: 5px;
      }
    }

    .price-info {
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;

      font-size: 24px;
      font-weight: 600;
      color: white;

      .from-price-wrapper {
        flex-direction: column;

        .from-price {
          font-size: 11px;
          font-weight: 400;
        }
      }

      .discover-button {
        @include button.outlined($size: small, $color-hover: colors.$green);

        width: 127px;
        height: 36px;
        padding: 0 12px;
      }
    }
  }
}

@include breakpoints.mobile() {
  .wrapper {
    width: v-bind(widthData);
    height: 100%;

    .favorite-title {
      @include shadowed-text.very-small();
    }

    .bottom-container {
      display: flex;
      height: 100%;

      .information-container {
        margin-top: 0;

        .top-info {
          display: none;
        }

        .days-nights-info {
          display: none;
        }

        .info-title {
          @include shadowed-text.very-small();

          z-index: 2;
          height: fit-content;
          margin-bottom: 12px;
        }
      }

      .price-info {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;

        width: 100%;
        margin-top: 16px;

        .from-price-wrapper {
          flex-direction: row;
          gap: 4px;
          align-items: baseline;
        }

        .discover-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
