<template>
  <div v-if="heightQuote" class="quote-sticky-banner">
    <NuxtLink class="quote-link" :class="{ space: hasAppointment }" :to="useQuoteLink()"
      >Demander un devis</NuxtLink
    >
    <NuxtLink v-if="hasAppointment" class="quote-link-light" href="#">Prendre un rdv</NuxtLink>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  hasAppointment?: boolean;
}>();

const windowStore = useWindow();

const heightQuote = computed(() => {
  return windowStore.scroll > 250;
});
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';

.quote-sticky-banner {
  display: none;
}

@include breakpoints.mobile() {
  .quote-sticky-banner {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 50px;

    color: colors.$white;

    background-color: colors.$green;
    border-top: 1px solid colors.$grey-300;

    transition: transform 0.3s ease-in-out;

    .quote-link {
      font-size: 17px;
      font-weight: 600;
      line-height: 47px;
      text-align: center;
      letter-spacing: 0;

      &.space {
        width: 50%;
      }
    }

    .quote-link-light {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50%;
      height: 100%;

      font-size: 17px;
      font-weight: 600;
      line-height: 47px;
      color: colors.$green;
      text-align: center;

      background-color: colors.$white;
    }
  }
}
</style>
