import { default as indexs4LVb3MUnVMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/[journey-slug]/index.vue?macro=true";
import { default as indexTswtcePP8JMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/index.vue?macro=true";
import { default as indexFDZWP1jSsbMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/carnet-pratique/[notebook-id]/index.vue?macro=true";
import { default as indexPSbUBgWEtWMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/index.vue?macro=true";
import { default as indexTA3tJwEozfMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/quand-partir-[destination-id]/index.vue?macro=true";
import { default as index5Ok8z1i204Meta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/accomodations/[hotel-id]/index.vue?macro=true";
import { default as indexzlCxTS2C1rMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/carnet-pratique/[notebook-id]/index.vue?macro=true";
import { default as indexgBSompp2g9Meta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/index.vue?macro=true";
import { default as indexR58whY0iBQMeta } from "/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/quand-partir-[destination-id]/index.vue?macro=true";
import { default as indexZOFa0YQFW7Meta } from "/vercel/path0/pages/[geographic-zone-slug]/index.vue?macro=true";
import { default as agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta } from "/vercel/path0/pages/agence-de-voyage-paris-6eme-arrondissement.vue?macro=true";
import { default as indexTGjvlBfglZMeta } from "/vercel/path0/pages/agence-de-voyage[agency-id]/index.vue?macro=true";
import { default as assurancesQ1Vh7eTb85Meta } from "/vercel/path0/pages/assurances.vue?macro=true";
import { default as indexMmmM384LR8Meta } from "/vercel/path0/pages/autour-du-voyage/actualite-voyageur/index.vue?macro=true";
import { default as indexCxS4PTTIGQMeta } from "/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/[article-slug]/index.vue?macro=true";
import { default as index7zGkAhisjaMeta } from "/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/index.vue?macro=true";
import { default as indexp8QuzriM7mMeta } from "/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/index.vue?macro=true";
import { default as indexKUEG7oq8NzMeta } from "/vercel/path0/pages/autour-du-voyage/catalogues/[article-slug]/index.vue?macro=true";
import { default as indexluxR0iEmcfMeta } from "/vercel/path0/pages/autour-du-voyage/catalogues/index.vue?macro=true";
import { default as indexTe1OKyumb4Meta } from "/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/index.vue?macro=true";
import { default as indexCElzN6n91oMeta } from "/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/inscription-evenement/index.vue?macro=true";
import { default as indexSuY6EeFRdqMeta } from "/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/index.vue?macro=true";
import { default as indexq5GS5W8jW8Meta } from "/vercel/path0/pages/autour-du-voyage/evenement-culturel/index.vue?macro=true";
import { default as indexSQhBMAmoM5Meta } from "/vercel/path0/pages/autour-du-voyage/index.vue?macro=true";
import { default as circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta } from "/vercel/path0/pages/circuit-groupe-de-a-a-z.vue?macro=true";
import { default as conditions_45de_45venterd5KKf6bKSMeta } from "/vercel/path0/pages/conditions-de-vente.vue?macro=true";
import { default as conditions_45generales_45dutilisationweYGgozRTgMeta } from "/vercel/path0/pages/conditions-generales-dutilisation.vue?macro=true";
import { default as indexCoUqDZFdbQMeta } from "/vercel/path0/pages/contrat-de-vente/index.vue?macro=true";
import { default as cookiesKBPlZe4JJJMeta } from "/vercel/path0/pages/cookies.vue?macro=true";
import { default as devis_45sur_45mesureGAo3cR5rgmMeta } from "/vercel/path0/pages/devis-sur-mesure.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexyGLVZbAVbnMeta } from "/vercel/path0/pages/inspiration-voyage/[category-id]/index.vue?macro=true";
import { default as indexli3gWzbYXXMeta } from "/vercel/path0/pages/inspiration-voyage/index.vue?macro=true";
import { default as la_45maison_45de_45theUgBPHCE7lKMeta } from "/vercel/path0/pages/la-maison-de-the.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as indexkrT2YGg0DcMeta } from "/vercel/path0/pages/newsletter/index.vue?macro=true";
import { default as indexUZGdCfUVUoMeta } from "/vercel/path0/pages/nos-conseillers/[expert-id]/index.vue?macro=true";
import { default as indexNDmtcZpSYvMeta } from "/vercel/path0/pages/nos-conseillers/index.vue?macro=true";
import { default as nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta } from "/vercel/path0/pages/nos-initiatives-depuis-plus-de-30-ans.vue?macro=true";
import { default as index8Xs3xcWuMDMeta } from "/vercel/path0/pages/notre-engagement-responsable/index.vue?macro=true";
import { default as indexeqFx1HmAcZMeta } from "/vercel/path0/pages/notre-valeur-ajoutee/index.vue?macro=true";
import { default as indexqzdsGCnyAxMeta } from "/vercel/path0/pages/nous-rejoindre/index.vue?macro=true";
import { default as index7AeGKu6fRUMeta } from "/vercel/path0/pages/ou-et-quand-partir/[period-id]/index.vue?macro=true";
import { default as indexmUfhWXuHfcMeta } from "/vercel/path0/pages/ou-et-quand-partir/index.vue?macro=true";
import { default as _91journey_45slug_93xAFWlg3Y7zMeta } from "/vercel/path0/pages/pdf/[journey-slug].vue?macro=true";
import { default as contrat_45de_45vente2xl2PqLacJMeta } from "/vercel/path0/pages/pdf/contrat-de-vente.vue?macro=true";
import { default as _91opportunity_45id_93wX4Gg3acHnMeta } from "/vercel/path0/pages/pdf/proposition/[opportunity-id].vue?macro=true";
import { default as politique_45de_45confidentialitek3BpmoJJifMeta } from "/vercel/path0/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexh2us4cqVB2Meta } from "/vercel/path0/pages/preview/[journey-code]/index.vue?macro=true";
import { default as indexAVuxb2K8gMMeta } from "/vercel/path0/pages/proposition/index.vue?macro=true";
import { default as indexBvQSWXEaVDMeta } from "/vercel/path0/pages/qui-sommes-nous/index.vue?macro=true";
import { default as indexG7Mu0AmxAnMeta } from "/vercel/path0/pages/revue-de-presse/index.vue?macro=true";
import { default as revue_45de_45presseglbjAk4LEGMeta } from "/vercel/path0/pages/revue-de-presse.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as indexxwTgtOxRICMeta } from "/vercel/path0/pages/tous-les-itineraires/index.vue?macro=true";
import { default as valeur_45ajoutee6YlTb3oiUXMeta } from "/vercel/path0/pages/valeur-ajoutee.vue?macro=true";
import { default as indextSYtP2C7k5Meta } from "/vercel/path0/pages/voyage-[country-slug]/index.vue?macro=true";
import { default as index84ctbqvdMzMeta } from "/vercel/path0/pages/voyages-groupe/index.vue?macro=true";
export default [
  {
    name: indexs4LVb3MUnVMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-typeorwishorjourneyslug-journeyslug",
    path: indexs4LVb3MUnVMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/:typeorwishorjourneyslug()/:journeyslug()",
    meta: indexs4LVb3MUnVMeta || {},
    alias: indexs4LVb3MUnVMeta?.alias || [],
    redirect: indexs4LVb3MUnVMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/[journey-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTswtcePP8JMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-typeorwishorjourneyslug",
    path: indexTswtcePP8JMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/:typeorwishorjourneyslug()",
    meta: indexTswtcePP8JMeta || {},
    alias: indexTswtcePP8JMeta?.alias || [],
    redirect: indexTswtcePP8JMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFDZWP1jSsbMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-carnet-pratique-notebookid",
    path: indexFDZWP1jSsbMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/carnet-pratique/:notebookid()",
    meta: indexFDZWP1jSsbMeta || {},
    alias: indexFDZWP1jSsbMeta?.alias || [],
    redirect: indexFDZWP1jSsbMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/carnet-pratique/[notebook-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPSbUBgWEtWMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug",
    path: indexPSbUBgWEtWMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()",
    meta: indexPSbUBgWEtWMeta || {},
    alias: indexPSbUBgWEtWMeta?.alias || [],
    redirect: indexPSbUBgWEtWMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTA3tJwEozfMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-quand-partir-destinationid",
    path: indexTA3tJwEozfMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/quand-partir-:destinationid()",
    meta: indexTA3tJwEozfMeta || {},
    alias: indexTA3tJwEozfMeta?.alias || [],
    redirect: indexTA3tJwEozfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/quand-partir-[destination-id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5Ok8z1i204Meta?.name ?? "geographiczoneslug-countryslug-accomodations-hotelid",
    path: index5Ok8z1i204Meta?.path ?? "/:geographiczoneslug()/:countryslug()/accomodations/:hotelid()",
    meta: index5Ok8z1i204Meta || {},
    alias: index5Ok8z1i204Meta?.alias || [],
    redirect: index5Ok8z1i204Meta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/accomodations/[hotel-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlCxTS2C1rMeta?.name ?? "geographiczoneslug-countryslug-carnet-pratique-notebookid",
    path: indexzlCxTS2C1rMeta?.path ?? "/:geographiczoneslug()/:countryslug()/carnet-pratique/:notebookid()",
    meta: indexzlCxTS2C1rMeta || {},
    alias: indexzlCxTS2C1rMeta?.alias || [],
    redirect: indexzlCxTS2C1rMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/carnet-pratique/[notebook-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgBSompp2g9Meta?.name ?? "geographiczoneslug-countryslug",
    path: indexgBSompp2g9Meta?.path ?? "/:geographiczoneslug()/:countryslug()",
    meta: indexgBSompp2g9Meta || {},
    alias: indexgBSompp2g9Meta?.alias || [],
    redirect: indexgBSompp2g9Meta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexR58whY0iBQMeta?.name ?? "geographiczoneslug-countryslug-quand-partir-destinationid",
    path: indexR58whY0iBQMeta?.path ?? "/:geographiczoneslug()/:countryslug()/quand-partir-:destinationid()",
    meta: indexR58whY0iBQMeta || {},
    alias: indexR58whY0iBQMeta?.alias || [],
    redirect: indexR58whY0iBQMeta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/[country-slug]/quand-partir-[destination-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZOFa0YQFW7Meta?.name ?? "geographiczoneslug",
    path: indexZOFa0YQFW7Meta?.path ?? "/:geographiczoneslug()",
    meta: indexZOFa0YQFW7Meta || {},
    alias: indexZOFa0YQFW7Meta?.alias || [],
    redirect: indexZOFa0YQFW7Meta?.redirect,
    component: () => import("/vercel/path0/pages/[geographic-zone-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta?.name ?? "agence-de-voyage-paris-6eme-arrondissement",
    path: agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta?.path ?? "/agence-de-voyage-paris-6eme-arrondissement",
    meta: agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta || {},
    alias: agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta?.alias || [],
    redirect: agence_45de_45voyage_45paris_456eme_45arrondissementduvvHjBX6JMeta?.redirect,
    component: () => import("/vercel/path0/pages/agence-de-voyage-paris-6eme-arrondissement.vue").then(m => m.default || m)
  },
  {
    name: indexTGjvlBfglZMeta?.name ?? "agence-de-voyageagencyid",
    path: indexTGjvlBfglZMeta?.path ?? "/agence-de-voyage:agencyid()",
    meta: indexTGjvlBfglZMeta || {},
    alias: indexTGjvlBfglZMeta?.alias || [],
    redirect: indexTGjvlBfglZMeta?.redirect,
    component: () => import("/vercel/path0/pages/agence-de-voyage[agency-id]/index.vue").then(m => m.default || m)
  },
  {
    name: assurancesQ1Vh7eTb85Meta?.name ?? "assurances",
    path: assurancesQ1Vh7eTb85Meta?.path ?? "/assurances",
    meta: assurancesQ1Vh7eTb85Meta || {},
    alias: assurancesQ1Vh7eTb85Meta?.alias || [],
    redirect: assurancesQ1Vh7eTb85Meta?.redirect,
    component: () => import("/vercel/path0/pages/assurances.vue").then(m => m.default || m)
  },
  {
    name: indexMmmM384LR8Meta?.name ?? "autour-du-voyage-actualite-voyageur",
    path: indexMmmM384LR8Meta?.path ?? "/autour-du-voyage/actualite-voyageur",
    meta: indexMmmM384LR8Meta || {},
    alias: indexMmmM384LR8Meta?.alias || [],
    redirect: indexMmmM384LR8Meta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/actualite-voyageur/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxS4PTTIGQMeta?.name ?? "autour-du-voyage-carnet-de-voyage-articlecategoryid-articleslug",
    path: indexCxS4PTTIGQMeta?.path ?? "/autour-du-voyage/carnet-de-voyage/:articlecategoryid()/:articleslug()",
    meta: indexCxS4PTTIGQMeta || {},
    alias: indexCxS4PTTIGQMeta?.alias || [],
    redirect: indexCxS4PTTIGQMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/[article-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index7zGkAhisjaMeta?.name ?? "autour-du-voyage-carnet-de-voyage-articlecategoryid",
    path: index7zGkAhisjaMeta?.path ?? "/autour-du-voyage/carnet-de-voyage/:articlecategoryid()",
    meta: index7zGkAhisjaMeta || {},
    alias: index7zGkAhisjaMeta?.alias || [],
    redirect: index7zGkAhisjaMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8QuzriM7mMeta?.name ?? "autour-du-voyage-carnet-de-voyage",
    path: indexp8QuzriM7mMeta?.path ?? "/autour-du-voyage/carnet-de-voyage",
    meta: indexp8QuzriM7mMeta || {},
    alias: indexp8QuzriM7mMeta?.alias || [],
    redirect: indexp8QuzriM7mMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/carnet-de-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: indexKUEG7oq8NzMeta?.name ?? "autour-du-voyage-catalogues-articleslug",
    path: indexKUEG7oq8NzMeta?.path ?? "/autour-du-voyage/catalogues/:articleslug()",
    meta: indexKUEG7oq8NzMeta || {},
    alias: indexKUEG7oq8NzMeta?.alias || [],
    redirect: indexKUEG7oq8NzMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/catalogues/[article-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexluxR0iEmcfMeta?.name ?? "autour-du-voyage-catalogues",
    path: indexluxR0iEmcfMeta?.path ?? "/autour-du-voyage/catalogues",
    meta: indexluxR0iEmcfMeta || {},
    alias: indexluxR0iEmcfMeta?.alias || [],
    redirect: indexluxR0iEmcfMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/catalogues/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe1OKyumb4Meta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid-eventslug",
    path: indexTe1OKyumb4Meta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()/:eventslug()",
    meta: indexTe1OKyumb4Meta || {},
    alias: indexTe1OKyumb4Meta?.alias || [],
    redirect: indexTe1OKyumb4Meta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCElzN6n91oMeta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid-eventslug-inscription-evenement",
    path: indexCElzN6n91oMeta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()/:eventslug()/inscription-evenement",
    meta: indexCElzN6n91oMeta || {},
    alias: indexCElzN6n91oMeta?.alias || [],
    redirect: indexCElzN6n91oMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/inscription-evenement/index.vue").then(m => m.default || m)
  },
  {
    name: indexSuY6EeFRdqMeta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid",
    path: indexSuY6EeFRdqMeta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()",
    meta: indexSuY6EeFRdqMeta || {},
    alias: indexSuY6EeFRdqMeta?.alias || [],
    redirect: indexSuY6EeFRdqMeta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/evenement-culturel/[event-category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexq5GS5W8jW8Meta?.name ?? "autour-du-voyage-evenement-culturel",
    path: indexq5GS5W8jW8Meta?.path ?? "/autour-du-voyage/evenement-culturel",
    meta: indexq5GS5W8jW8Meta || {},
    alias: indexq5GS5W8jW8Meta?.alias || [],
    redirect: indexq5GS5W8jW8Meta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/evenement-culturel/index.vue").then(m => m.default || m)
  },
  {
    name: indexSQhBMAmoM5Meta?.name ?? "autour-du-voyage",
    path: indexSQhBMAmoM5Meta?.path ?? "/autour-du-voyage",
    meta: indexSQhBMAmoM5Meta || {},
    alias: indexSQhBMAmoM5Meta?.alias || [],
    redirect: indexSQhBMAmoM5Meta?.redirect,
    component: () => import("/vercel/path0/pages/autour-du-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta?.name ?? "circuit-groupe-de-a-a-z",
    path: circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta?.path ?? "/circuit-groupe-de-a-a-z",
    meta: circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta || {},
    alias: circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta?.alias || [],
    redirect: circuit_45groupe_45de_45a_45a_45zrgP4NWUceiMeta?.redirect,
    component: () => import("/vercel/path0/pages/circuit-groupe-de-a-a-z.vue").then(m => m.default || m)
  },
  {
    name: conditions_45de_45venterd5KKf6bKSMeta?.name ?? "conditions-de-vente",
    path: conditions_45de_45venterd5KKf6bKSMeta?.path ?? "/conditions-de-vente",
    meta: conditions_45de_45venterd5KKf6bKSMeta || {},
    alias: conditions_45de_45venterd5KKf6bKSMeta?.alias || [],
    redirect: conditions_45de_45venterd5KKf6bKSMeta?.redirect,
    component: () => import("/vercel/path0/pages/conditions-de-vente.vue").then(m => m.default || m)
  },
  {
    name: conditions_45generales_45dutilisationweYGgozRTgMeta?.name ?? "conditions-generales-dutilisation",
    path: conditions_45generales_45dutilisationweYGgozRTgMeta?.path ?? "/conditions-generales-dutilisation",
    meta: conditions_45generales_45dutilisationweYGgozRTgMeta || {},
    alias: conditions_45generales_45dutilisationweYGgozRTgMeta?.alias || [],
    redirect: conditions_45generales_45dutilisationweYGgozRTgMeta?.redirect,
    component: () => import("/vercel/path0/pages/conditions-generales-dutilisation.vue").then(m => m.default || m)
  },
  {
    name: indexCoUqDZFdbQMeta?.name ?? "contrat-de-vente",
    path: indexCoUqDZFdbQMeta?.path ?? "/contrat-de-vente",
    meta: indexCoUqDZFdbQMeta || {},
    alias: indexCoUqDZFdbQMeta?.alias || [],
    redirect: indexCoUqDZFdbQMeta?.redirect,
    component: () => import("/vercel/path0/pages/contrat-de-vente/index.vue").then(m => m.default || m)
  },
  {
    name: cookiesKBPlZe4JJJMeta?.name ?? "cookies",
    path: cookiesKBPlZe4JJJMeta?.path ?? "/cookies",
    meta: cookiesKBPlZe4JJJMeta || {},
    alias: cookiesKBPlZe4JJJMeta?.alias || [],
    redirect: cookiesKBPlZe4JJJMeta?.redirect,
    component: () => import("/vercel/path0/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: devis_45sur_45mesureGAo3cR5rgmMeta?.name ?? "devis-sur-mesure",
    path: devis_45sur_45mesureGAo3cR5rgmMeta?.path ?? "/devis-sur-mesure",
    meta: devis_45sur_45mesureGAo3cR5rgmMeta || {},
    alias: devis_45sur_45mesureGAo3cR5rgmMeta?.alias || [],
    redirect: devis_45sur_45mesureGAo3cR5rgmMeta?.redirect,
    component: () => import("/vercel/path0/pages/devis-sur-mesure.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGLVZbAVbnMeta?.name ?? "inspiration-voyage-categoryid",
    path: indexyGLVZbAVbnMeta?.path ?? "/inspiration-voyage/:categoryid()",
    meta: indexyGLVZbAVbnMeta || {},
    alias: indexyGLVZbAVbnMeta?.alias || [],
    redirect: indexyGLVZbAVbnMeta?.redirect,
    component: () => import("/vercel/path0/pages/inspiration-voyage/[category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexli3gWzbYXXMeta?.name ?? "inspiration-voyage",
    path: indexli3gWzbYXXMeta?.path ?? "/inspiration-voyage",
    meta: indexli3gWzbYXXMeta || {},
    alias: indexli3gWzbYXXMeta?.alias || [],
    redirect: indexli3gWzbYXXMeta?.redirect,
    component: () => import("/vercel/path0/pages/inspiration-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: la_45maison_45de_45theUgBPHCE7lKMeta?.name ?? "la-maison-de-the",
    path: la_45maison_45de_45theUgBPHCE7lKMeta?.path ?? "/la-maison-de-the",
    meta: la_45maison_45de_45theUgBPHCE7lKMeta || {},
    alias: la_45maison_45de_45theUgBPHCE7lKMeta?.alias || [],
    redirect: la_45maison_45de_45theUgBPHCE7lKMeta?.redirect,
    component: () => import("/vercel/path0/pages/la-maison-de-the.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesaI7UrXH82KMeta?.name ?? "mentions-legales",
    path: mentions_45legalesaI7UrXH82KMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesaI7UrXH82KMeta || {},
    alias: mentions_45legalesaI7UrXH82KMeta?.alias || [],
    redirect: mentions_45legalesaI7UrXH82KMeta?.redirect,
    component: () => import("/vercel/path0/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: indexkrT2YGg0DcMeta?.name ?? "newsletter",
    path: indexkrT2YGg0DcMeta?.path ?? "/newsletter",
    meta: indexkrT2YGg0DcMeta || {},
    alias: indexkrT2YGg0DcMeta?.alias || [],
    redirect: indexkrT2YGg0DcMeta?.redirect,
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: indexUZGdCfUVUoMeta?.name ?? "nos-conseillers-expertid",
    path: indexUZGdCfUVUoMeta?.path ?? "/nos-conseillers/:expertid()",
    meta: indexUZGdCfUVUoMeta || {},
    alias: indexUZGdCfUVUoMeta?.alias || [],
    redirect: indexUZGdCfUVUoMeta?.redirect,
    component: () => import("/vercel/path0/pages/nos-conseillers/[expert-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNDmtcZpSYvMeta?.name ?? "nos-conseillers",
    path: indexNDmtcZpSYvMeta?.path ?? "/nos-conseillers",
    meta: indexNDmtcZpSYvMeta || {},
    alias: indexNDmtcZpSYvMeta?.alias || [],
    redirect: indexNDmtcZpSYvMeta?.redirect,
    component: () => import("/vercel/path0/pages/nos-conseillers/index.vue").then(m => m.default || m)
  },
  {
    name: nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta?.name ?? "nos-initiatives-depuis-plus-de-30-ans",
    path: nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta?.path ?? "/nos-initiatives-depuis-plus-de-30-ans",
    meta: nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta || {},
    alias: nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta?.alias || [],
    redirect: nos_45initiatives_45depuis_45plus_45de_4530_45ansXOm0BqJRvZMeta?.redirect,
    component: () => import("/vercel/path0/pages/nos-initiatives-depuis-plus-de-30-ans.vue").then(m => m.default || m)
  },
  {
    name: index8Xs3xcWuMDMeta?.name ?? "notre-engagement-responsable",
    path: index8Xs3xcWuMDMeta?.path ?? "/notre-engagement-responsable",
    meta: index8Xs3xcWuMDMeta || {},
    alias: index8Xs3xcWuMDMeta?.alias || [],
    redirect: index8Xs3xcWuMDMeta?.redirect,
    component: () => import("/vercel/path0/pages/notre-engagement-responsable/index.vue").then(m => m.default || m)
  },
  {
    name: indexeqFx1HmAcZMeta?.name ?? "notre-valeur-ajoutee",
    path: indexeqFx1HmAcZMeta?.path ?? "/notre-valeur-ajoutee",
    meta: indexeqFx1HmAcZMeta || {},
    alias: indexeqFx1HmAcZMeta?.alias || [],
    redirect: indexeqFx1HmAcZMeta?.redirect,
    component: () => import("/vercel/path0/pages/notre-valeur-ajoutee/index.vue").then(m => m.default || m)
  },
  {
    name: indexqzdsGCnyAxMeta?.name ?? "nous-rejoindre",
    path: indexqzdsGCnyAxMeta?.path ?? "/nous-rejoindre",
    meta: indexqzdsGCnyAxMeta || {},
    alias: indexqzdsGCnyAxMeta?.alias || [],
    redirect: indexqzdsGCnyAxMeta?.redirect,
    component: () => import("/vercel/path0/pages/nous-rejoindre/index.vue").then(m => m.default || m)
  },
  {
    name: index7AeGKu6fRUMeta?.name ?? "ou-et-quand-partir-periodid",
    path: index7AeGKu6fRUMeta?.path ?? "/ou-et-quand-partir/:periodid()",
    meta: index7AeGKu6fRUMeta || {},
    alias: index7AeGKu6fRUMeta?.alias || [],
    redirect: index7AeGKu6fRUMeta?.redirect,
    component: () => import("/vercel/path0/pages/ou-et-quand-partir/[period-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmUfhWXuHfcMeta?.name ?? "ou-et-quand-partir",
    path: indexmUfhWXuHfcMeta?.path ?? "/ou-et-quand-partir",
    meta: indexmUfhWXuHfcMeta || {},
    alias: indexmUfhWXuHfcMeta?.alias || [],
    redirect: indexmUfhWXuHfcMeta?.redirect,
    component: () => import("/vercel/path0/pages/ou-et-quand-partir/index.vue").then(m => m.default || m)
  },
  {
    name: _91journey_45slug_93xAFWlg3Y7zMeta?.name ?? "pdf-journeyslug",
    path: _91journey_45slug_93xAFWlg3Y7zMeta?.path ?? "/pdf/:journeyslug()",
    meta: _91journey_45slug_93xAFWlg3Y7zMeta || {},
    alias: _91journey_45slug_93xAFWlg3Y7zMeta?.alias || [],
    redirect: _91journey_45slug_93xAFWlg3Y7zMeta?.redirect,
    component: () => import("/vercel/path0/pages/pdf/[journey-slug].vue").then(m => m.default || m)
  },
  {
    name: contrat_45de_45vente2xl2PqLacJMeta?.name ?? "pdf-contrat-de-vente",
    path: contrat_45de_45vente2xl2PqLacJMeta?.path ?? "/pdf/contrat-de-vente",
    meta: contrat_45de_45vente2xl2PqLacJMeta || {},
    alias: contrat_45de_45vente2xl2PqLacJMeta?.alias || [],
    redirect: contrat_45de_45vente2xl2PqLacJMeta?.redirect,
    component: () => import("/vercel/path0/pages/pdf/contrat-de-vente.vue").then(m => m.default || m)
  },
  {
    name: _91opportunity_45id_93wX4Gg3acHnMeta?.name ?? "pdf-proposition-opportunityid",
    path: _91opportunity_45id_93wX4Gg3acHnMeta?.path ?? "/pdf/proposition/:opportunityid()",
    meta: _91opportunity_45id_93wX4Gg3acHnMeta || {},
    alias: _91opportunity_45id_93wX4Gg3acHnMeta?.alias || [],
    redirect: _91opportunity_45id_93wX4Gg3acHnMeta?.redirect,
    component: () => import("/vercel/path0/pages/pdf/proposition/[opportunity-id].vue").then(m => m.default || m)
  },
  {
    name: politique_45de_45confidentialitek3BpmoJJifMeta?.name ?? "politique-de-confidentialite",
    path: politique_45de_45confidentialitek3BpmoJJifMeta?.path ?? "/politique-de-confidentialite",
    meta: politique_45de_45confidentialitek3BpmoJJifMeta || {},
    alias: politique_45de_45confidentialitek3BpmoJJifMeta?.alias || [],
    redirect: politique_45de_45confidentialitek3BpmoJJifMeta?.redirect,
    component: () => import("/vercel/path0/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: indexh2us4cqVB2Meta?.name ?? "preview-journeycode",
    path: indexh2us4cqVB2Meta?.path ?? "/preview/:journeycode()",
    meta: indexh2us4cqVB2Meta || {},
    alias: indexh2us4cqVB2Meta?.alias || [],
    redirect: indexh2us4cqVB2Meta?.redirect,
    component: () => import("/vercel/path0/pages/preview/[journey-code]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAVuxb2K8gMMeta?.name ?? "proposition",
    path: indexAVuxb2K8gMMeta?.path ?? "/proposition",
    meta: indexAVuxb2K8gMMeta || {},
    alias: indexAVuxb2K8gMMeta?.alias || [],
    redirect: indexAVuxb2K8gMMeta?.redirect,
    component: () => import("/vercel/path0/pages/proposition/index.vue").then(m => m.default || m)
  },
  {
    name: indexBvQSWXEaVDMeta?.name ?? "qui-sommes-nous",
    path: indexBvQSWXEaVDMeta?.path ?? "/qui-sommes-nous",
    meta: indexBvQSWXEaVDMeta || {},
    alias: indexBvQSWXEaVDMeta?.alias || [],
    redirect: indexBvQSWXEaVDMeta?.redirect,
    component: () => import("/vercel/path0/pages/qui-sommes-nous/index.vue").then(m => m.default || m)
  },
  {
    name: revue_45de_45presseglbjAk4LEGMeta?.name ?? undefined,
    path: revue_45de_45presseglbjAk4LEGMeta?.path ?? "/revue-de-presse",
    meta: revue_45de_45presseglbjAk4LEGMeta || {},
    alias: revue_45de_45presseglbjAk4LEGMeta?.alias || [],
    redirect: revue_45de_45presseglbjAk4LEGMeta?.redirect,
    component: () => import("/vercel/path0/pages/revue-de-presse.vue").then(m => m.default || m),
    children: [
  {
    name: indexG7Mu0AmxAnMeta?.name ?? "revue-de-presse",
    path: indexG7Mu0AmxAnMeta?.path ?? "",
    meta: indexG7Mu0AmxAnMeta || {},
    alias: indexG7Mu0AmxAnMeta?.alias || [],
    redirect: indexG7Mu0AmxAnMeta?.redirect,
    component: () => import("/vercel/path0/pages/revue-de-presse/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: testlK5QpQxp8eMeta?.name ?? "test",
    path: testlK5QpQxp8eMeta?.path ?? "/test",
    meta: testlK5QpQxp8eMeta || {},
    alias: testlK5QpQxp8eMeta?.alias || [],
    redirect: testlK5QpQxp8eMeta?.redirect,
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexxwTgtOxRICMeta?.name ?? "tous-les-itineraires",
    path: indexxwTgtOxRICMeta?.path ?? "/tous-les-itineraires",
    meta: indexxwTgtOxRICMeta || {},
    alias: indexxwTgtOxRICMeta?.alias || [],
    redirect: indexxwTgtOxRICMeta?.redirect,
    component: () => import("/vercel/path0/pages/tous-les-itineraires/index.vue").then(m => m.default || m)
  },
  {
    name: valeur_45ajoutee6YlTb3oiUXMeta?.name ?? "valeur-ajoutee",
    path: valeur_45ajoutee6YlTb3oiUXMeta?.path ?? "/valeur-ajoutee",
    meta: valeur_45ajoutee6YlTb3oiUXMeta || {},
    alias: valeur_45ajoutee6YlTb3oiUXMeta?.alias || [],
    redirect: valeur_45ajoutee6YlTb3oiUXMeta?.redirect,
    component: () => import("/vercel/path0/pages/valeur-ajoutee.vue").then(m => m.default || m)
  },
  {
    name: indextSYtP2C7k5Meta?.name ?? "voyage-countryslug",
    path: indextSYtP2C7k5Meta?.path ?? "/voyage-:countryslug()",
    meta: indextSYtP2C7k5Meta || {},
    alias: indextSYtP2C7k5Meta?.alias || [],
    redirect: indextSYtP2C7k5Meta?.redirect,
    component: () => import("/vercel/path0/pages/voyage-[country-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index84ctbqvdMzMeta?.name ?? "voyages-groupe",
    path: index84ctbqvdMzMeta?.path ?? "/voyages-groupe",
    meta: index84ctbqvdMzMeta || {},
    alias: index84ctbqvdMzMeta?.alias || [],
    redirect: index84ctbqvdMzMeta?.redirect,
    component: () => import("/vercel/path0/pages/voyages-groupe/index.vue").then(m => m.default || m)
  }
]